/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { ContextProvider } from "./src/components/Context"

const transitionDelay = 500

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if(location.pathname === '/talents') {
    window.setTimeout(() => window.scrollTo(0, 0), 1000)
  } else {
    if (location.action === 'PUSH') {
      window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay
      )
    }
  }
  return false
}

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
)